import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Link,
} from 'react-router-dom';

import Root from "./routes/root.jsx";
import { UpdatePassword } from './UpdatePassword.js'
import ErrorPage from "./error-page.jsx";

import './styles.css';

import Board from './App.js';
import { ForgotPassword } from './ForgotPassword.js';
import { RegisterUser } from './RegisterUser.js';
import { VerifyEmail } from './VerifyEmail.js';
import {LoginUser} from './LoginUser.js'
import { ShareInformation } from './ShareInformation.js';
import { PensionInformation } from './PensionInformation.js';
import { WealthInformation } from './WealthInformation.js';
import { GrowthInformation } from './Growthformation.js';
import { LogOut } from './LogOut.js';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "shareinformation/",
        element: <ShareInformation />
      },
      {
        path: "pensioninformation/",
        element: <PensionInformation />
      },
      {
        path: "wealthinformation/",
        element: <WealthInformation />
      },
      {
        path: "growthinformation/",
        element: <GrowthInformation />
      },
      {
        path: "login/",
        element: <LoginUser />
      },
      {
        path: "logout/",
        element: <LogOut />
      },
      {
        path: "updatepassword/:tid",
        element: <UpdatePassword />,
      },
      {
        path: "verifyemail/:tid",
        element: <VerifyEmail />,
      },
      {
        path: "forgotpassword/",
        element: <ForgotPassword />,
      },
      {
        path: "registeruser/",
        element: <RegisterUser />,
      },
      {
        path: "",
        element: <LoginUser />
      },
    ],
  }
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);