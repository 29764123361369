import { Component } from 'react';
import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {PasswordInput} from './passwordinput.js';
import { useParams } from 'react-router';
import { AlertDismiss } from './AlertDismiss.js';

export const UPPERCASE_REGEX = new RegExp(/.*[A-Z]/);
export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const SPECIAL_CHARS_REGEX = new RegExp(/.*[!#$%&@£*+/=?^_{|}']/);
export const EMAIL_REGEX = new RegExp(/^(?:[a-z0-9_]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/, "i");

export const PASSWORD_VALID_REGEX = new RegExp(
  `^(?=${[
    LENGTH_REGEX.source,
    UPPERCASE_REGEX.source,
    NUMBER_REGEX.source,
    SPECIAL_CHARS_REGEX.source
  ].join(")(?=")}).*$`
);

export const UpdatePassword = () => {
  const [messageShow, setMessageShow] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias',
    loginToken: "8C986FA5-A72E-4EF9-A371-18F40F64D112"
  });

  const UpdatePasswordWS = async (data) => {
    console.log(JSON.stringify(data));
    var success = false;

    const response = await fetch('https://localhost:44386/api/User/update', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': 'https://localhost:44386'
      },
    })    
      .then((response) => {
        success = true;
        return response;
      })
      .catch((err) => {
        console.log("Error");
        console.log(err);

      });

    if (response) {
      console.log(response);
    }

    return success;
  }

  const handleSubmit = (e, tokenId) => {  
    e.preventDefault();
    setMessageShow(false);
    setAlertMessage("");
    setButtonDisabled(true);

    if(userData.password.match(PASSWORD_VALID_REGEX, "i")) {
      //clear the error message
      setAlertMessage('');

      setUserData({
        ...userData,
        loginToken: tokenId
      });

      console.log(userData);

      UpdatePasswordWS(userData).then((result) => {
        if(result == true) {
          //close the dialog
          //maybe show a confirmation before this?
          setConfirmationBox(true);
        } else {
          //error during insert of new user
          setAlertMessage('An error occurred while trying to update password. Please try again.'); 
          setMessageShow(true);  
        }
      });
    } else {
      setAlertMessage('Password not valid'); 
      setMessageShow(true);   
    }

    setButtonDisabled(false);    
  };

  const handleCancel = (e) => {
    e.preventDefault();
    const {onClose} = this.props;

    onClose();
  }

  const UserConfirmationBox = () => {

    return (
      <>
        <h1 style={{display: "grid", justifyContent: "center"}}>
          <Badge bg="secondary" >User Registration</Badge>
        </h1>
        <div style={{display: "grid", justifyContent: "center"}}>
          Update password request has been successful! 
          <p>
            You should now be able to login to the system using your username and newly updated password.
          </p>
        </div>
        <div className="d-flex justify-content-end">
            <Button onClick={(e) => handleCancel(e)} variant="outline-success">
              Return to Login
            </Button>
        </div>          

      </>
    )
  }

  const UserInputBox = () => {
    //get passed in parameters
    const params = useParams();

    return(
    <>     
      <div>
        <AlertDismiss heading="Updating password error!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>

      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Welcome to Lexi Game</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Update Password</Badge>
      </h2>
      <form className='form-box'>
        <div style={{display: "grid", justifyContent: "center"}}>
          <div >
          <label>
              Password:
              <PasswordInput 
                onChange={e => {
                  setUserData({
                    ...userData,
                    password: e
                  })
                  }}  
                classStyle='form-control'/>
            </label>
          </div>    
          <div>
            <label>
              Confirm Password:
              <input 
                className='form-control'
                type="password" 
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </label>
          </div>
          <p></p>
          <div>            
            {/* Use the passed in parameter token ID in the submit call */}
            <button disabled={buttonDisabled} onClick={(e) => handleSubmit(e, params.tid)}>Submit</button>          
            {"\u00a0\u00a0"}            
            <button disabled={buttonDisabled} onClick={(e) => handleCancel(e)}>Cancel</button>
          </div>
        </div>
      </form>                
      <p>      
      </p>
    </>);
  }

  return (
    <>
    {confirmationBox === true ?
      (
        <div>
          <UserConfirmationBox />
        </div>
      ) :
      (
        <div>              
          <UserInputBox />
        </div>
      )
    }
    </>
  );
  
}