import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { DWButton } from './button.js';
import { RegisterUser } from './RegisterUser.js'
import { LoginUser } from './LoginUser.js'
import { ForgotPassword } from './ForgotPassword.js'
import { UpdatePassword } from './UpdatePassword.js'


function App() {

  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias'
  })

  function onButtonClick() {
    console.info('Clicked');
  }

  const [show, setShow] = useState(false);
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [token, setToken] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [registerUser, setRegisterUser] = useState(false);
  const [loginUser, setLoginUser] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  function AlertDismiss({ message }) {
    return (
      <>
        <Alert show={show} onClose={() => setShow(false)} variant="warning" dismissible>
          <Alert.Heading>Creating new user error!</Alert.Heading>
          <p>
            {message}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShow(false)} variant="outline-success">
              Close this popup
            </Button>
          </div>
        </Alert>
      </>
    );
  }

  const testOutput = (e) => {
    console.log('updated :' + e);
  }

  const getPosts = async () => {
    await fetch('https://jsonplaceholder.typicode.com/posts?_limit=10')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err.messsage);
      });
  }

  const displayRegisterUser = () => {
    if (registerUser == true) {
      return (
        <RegisterUser />
      );
    } else {
      return
    }
  }

  const closeRegisterUserDialog = ()=> {
    setRegisterUser(false);
  }

  const closeLoginUserDialog = ()=> {
    setLoginUser(false);
  }
  
  const closeForgotPasswordDialog = ()=> {
    setForgotPassword(false);
  }
  
  const closeUpdatePasswordDialog = ()=> {
    setUpdatePassword(false);
  }

  const handleLogin = (e) => {
    e.preventDefault();

    setLoginUser(true);
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();

    setForgotPassword(true);
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();

    setUpdatePassword(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setRegisterUser(true);
  };

  return (
    <>
      {/* {isGuest ? (
        <p>hello guest</p>
      ) : (
        <p>hello user</p>

      )}

      {isGuest && (<p>hello guest</p>)} */}


      {loginUser === true ?
        (<LoginUser onClose={closeLoginUserDialog} />) :
        (<div>
          <button onClick={(e) => handleLogin(e)}>Login</button>
        </div>)
      }


      {registerUser === true ?
        (<RegisterUser onClose={closeRegisterUserDialog} />) :
        (<div>
          <button onClick={(e) => handleSubmit(e)}>Register User</button>
        </div>)
      }

      {forgotPassword == true ?
        (<ForgotPassword onClose={closeForgotPasswordDialog} />) :
        (<div>
          <button onClick={(e) => handleForgotPassword(e)}>Forgot Password</button>
        </div>)
      }

      {updatePassword == true ?
        (<UpdatePassword onClose={closeUpdatePasswordDialog} />) :
        (<div>
          <button onClick={(e) => handleUpdatePassword(e)}>Update Password</button>
        </div>)
      }
{/* 
      <DWButton value="click me"
        whenClicked={() => {
          testOutput()
        }}
      /> */}

      <p>

        {token}
        {alertMessage}
        {posts.map((post) => {
          return (
            <div className="post-card">
              <h2 className="post-title">{post.token}</h2>
            </div>
          );
        })}
      </p>
    </>
  );
}


export default App;