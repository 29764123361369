import { Component } from 'react';
import { ImCheckmark } from 'react-icons/im/index.js';

export class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validPassword: false,
            showPassword: false,
            passwordValue: "",
            UPPERCASE_REGEX: new RegExp(/.*[A-Z]/),
            NUMBER_REGEX: new RegExp(/.*\d/),
            SPECIAL_CHARS_REGEX: new RegExp(/.*[!#$%&@£*+/=?^_{|}']/),
            LENGTH_REGEX: new RegExp(/.{8,}$/),
            style:{bakground: 'red', color: 'black'},
            message: 'Welcome visitor'
        }
    }    


     CheckList = ({ value }) => {
        const style = this.state;
        
        const rules = [
            { label: "One uppercase", pattern: this.state.UPPERCASE_REGEX },
            { label: "One number", pattern: this.state.NUMBER_REGEX },
            { label: "Min 8 characters", pattern: this.state.LENGTH_REGEX },
            { label: "One special char", pattern: this.state.SPECIAL_CHARS_REGEX }
          ];

        return (
          <div className="pwdrules">
            {rules.map((rule, index) => {
                const cn = value && value.match(rule.pattern) ? "success" : "";
                    
                if(cn != "") {
                    return (<p className={cn} key={index}><ImCheckmark /> {rule.label}</p>);
                } else {
                    return (<p className={cn} key={index}>{rule.label}</p>);
                }
            })}                
          </div>
        );
    }; 

    togglePasswordVisiblity() {
        const {showPwd} = this.state.showPassword;

        this.setState({
            showPassword: !{showPwd}
        })
    };

    setValidPassword(value) {
        this.setState({
            validPassword: value
        })
    };

    setPassword(pwd) {
        this.setState({
            passwordValue: pwd
        })
    } 

    render() {
        const {onChange, classStyle} = this.props;

        const PASSWORD_VALID_REGEX = new RegExp(
            `^(?=${[
                this.state.LENGTH_REGEX.source,
                this.state.UPPERCASE_REGEX.source,
                this.state.NUMBER_REGEX.source,
                this.state.SPECIAL_CHARS_REGEX.source
            ].join(")(?=")}).*$`);


        return(
          <>
          <div className='pwdrules'>
            <input 
              className={classStyle}
              type="password"
              onChange={e => {

                    if(e.target.value.match(PASSWORD_VALID_REGEX)) {
                        this.setValidPassword(true);                    
                        console.log(e.target.value);
                    } else {
                        this.setValidPassword(false);
                    }
                    //call this method
                    onChange(e.target.value);
                    this.setPassword(e.target.value);
                }}        
            />
            <p className={this.state.validPassword ? "success" : "" }>Password validity</p>
            <this.CheckList value={this.state.passwordValue} /> 
          </div>
          </>
        );
      } 
}