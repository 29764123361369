import { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import { AlertDismiss } from './AlertDismiss.js';

export const VerifyEmail = () => { 
  const [messageShow, setMessageShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [callMade, setCallMade] = useState(false);
   
  const verifyEmailWS = async (tokenId) => {
    console.log(tokenId);
    var success = false;

    const response = await fetch('https://localhost:44386/api/User/validate?loginToken='+tokenId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Origin': 'https://localhost:44386'
      },
    })    
      .then((response) => {
        success = true;
        return response;
      })
      .catch((err) => {
        console.log("Error");
        console.log(err);

      });

    if (response) {
      console.log(response);
    }

    return success;
  }

  const callVerifyEmail = (tokenId) => {
    verifyEmailWS(tokenId).then((result) => {
      if(result == true) {
        //close the dialog
        //maybe show a confirmation before this?
        setConfirmationBox(true);
      } else {
        //error during insert of new user
        setAlertMessage('An error occurred while trying to verify email address. Please try again.'); 
        setShow(true);  
      }

      setCallMade(true);
    });
  }

  const handleCancel = (e) => {
    e.preventDefault();
    const {onClose} = props;

    onClose();
  }

  const UserConfirmationBox = () => {

    return (
      <>
        <h1 style={{display: "grid", justifyContent: "center"}}>
          <Badge bg="secondary" >User Registration</Badge>
        </h1>
        <div style={{display: "grid", justifyContent: "center"}}>
          Verify email address request has been successful! 
          <p>
            You should now be able to login to the system using your email as a username.
          </p>
        </div>
        <div className="d-flex justify-content-end">
            <Button onClick={(e) => handleCancel(e)} variant="outline-success">
              Return to Login
            </Button>
        </div>          

      </>
    )
  }

  const UserInputBox = () => {
    //get passed in parameters
    const params = useParams();

    if(callMade == false) {
      console.log("Call made is false");
      callVerifyEmail(params.tid);      
      
    }
    
    return(
    <>     
      <div>
        <AlertDismiss heading="Verify Email Error!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>

      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Welcome to Lexi Game</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Verify Email Page</Badge>
      </h2>
      <form className='form-box'>
        <div style={{display: "grid", justifyContent: "center"}}>
          <div >
          </div>    
          <div>
          </div>
          <p></p>
          <div>            
          </div>
        </div>
      </form>                
      <p>      
      </p>
    </>);
  }


  return (
    <>
      {confirmationBox === true ?
        (
          <div>
            <UserConfirmationBox />
          </div>
        ) :
        (
          <div>              
            <UserInputBox />
          </div>
        )
      }
    </>
  );    
}