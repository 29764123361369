import { Component } from 'react';
import { useState, useEffect } from 'react';
import { Badge, ButtonGroup, Spinner, Form, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {PasswordInput} from './passwordinput.js';
import { useNavigate } from 'react-router-dom';
import { AlertDismiss } from './AlertDismiss.js';

export const EMAIL_REGEX = new RegExp(/^(?:[a-z0-9_]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/, "i");

export const LoginUser = () => {
  const navigate = useNavigate();
  const [messageShow, setMessageShow] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [token, setToken] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias'
  });
  const [saving, setSaving] = useState(false);

  const saveToken = (token, username) => {
    sessionStorage.setItem("playerInfo", token);
    sessionStorage.setItem("loggedUser", username);
  }

  const handleRedirect = () => {    
     navigate("/shareinformation");
  }

  const checkUser = async (loginCreds) => {
    console.log(JSON.stringify(loginCreds));
    setSaving(true);
    var success = false;
    var token = "";

    const response = await fetch('https://thepinkgiraffe.azurewebsites.net/User', {
      method: 'POST',
      body: JSON.stringify(loginCreds),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',        
        'Origin': 'https://localhost:3000'
      },
    })
      .then((response) => {
        console.log(response);        
        return response.json()
      })
      .then((data) => {
        if (data.token) {
          success = true;
          token = data.token;
          setToken(token);
          saveToken(token, loginCreds.username);
          setSaving(false);
        } else {
          console.log("No token");
          //set alert message
          setAlertMessage("Login attempt failed.");
          setMessageShow(true);
        }
      })
      .catch((err) => {
        console.log(err.messsage);
      });

    if (response) {
      console.log(response);
    }

    setSaving(false);

    return {
          Result: success,
          Token: token
    };

  }

  const handleSubmit = (e) => {  

    if(e) {
      e.preventDefault();
    }
    
    setButtonDisabled(true);
      
    if(userData.username.match(EMAIL_REGEX)) {
      //clear the error message
      setAlertMessage('');
      
      checkUser(userData).then((result) => {
        if(result.Result == true) {
          handleRedirect(); 
          
        } else {
          console.log("failed");
        }
      });
    } else {
      setAlertMessage('Email address not valid'); 
      setMessageShow(true);   
    }

    setButtonDisabled(false);      
  };

  const handleCancel = (e) => {

    if(e) {
      e.preventDefault();
    }
  
  }

  return (
    <>
      <div>
        <AlertDismiss heading="Login Error!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>

      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Welcome to Wealth</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Login</Badge>
      </h2>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Form.Group className="mb-3" controlId="login.email">
          <Form.Label>Email address:</Form.Label>
          <Form.Control 
            type="email" 
            value={userData.username} 
            onChange={(e) => setUserData({
              ...userData, 
              username: e.target.value})}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="login.password">
          <Form.Label>Password:</Form.Label>
          <Form.Control 
            type="password" 
            value={userData.password}
            onChange={(e) => setUserData({
              ...userData,
              password: e.target.value})}
            aria-describedBy="passwordHelpBlock"
          />
          <Form.Text id="passwordHelpBlock" muted>
            Your password must be 8-20 characters long, contain letters, numbers,
            and special characters, but no emoji.
          </Form.Text>
        </Form.Group>
        <Form.Group className="mb-3" controlId="login.buttons">
          {saving == false && (
            <>
              <Button variant="primary" type="submit" onClick={(e) => handleSubmit(e)}>Submit</Button>
              {' '}
              <Button variant="secondary"onClick={(e) => handleCancel(e)}>Cancel</Button>
            </>
          )}
          {saving == true && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Savings...</span>
            </Spinner>
          )}
        </Form.Group>
      </Form>
    </>
  );
   
}
