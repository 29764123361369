import { Component } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Badge, Container, Row, Col, Spinner, Navbar, Nav, Form, NavDropdown, Modal, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip ,Legend, AreaChart, Area, ResponsiveContainer} from 'recharts';
import { AlertDismiss } from './AlertDismiss.js';
import dateFormat from 'dateformat';
import Table from "./Table.js";
import CurrencyInput from 'react-currency-input-field';
import { Moment, now } from 'moment/moment.js';

export const WealthOverall = ({posts, isChecked}) => { 

  const formatXAxis = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm");
  }

  const formatLabel = (value) => {
    // If using moment.js
    return "£" + value.toFixed(2).toString();
  }

  const formatPercentage = (value) => {
    // If using moment.js
    return value.toFixed(2).toString() + "%";
  }

  const createOverallArray = () => {
    const lineGraph = []
    
    posts.map((post) => {    
      //if this item should be included in total
      if(isChecked(post.id)) {
        
        post.wv.map((dataitem) => {

          if(lineGraph.some(i => { if(i.date === dataitem.vd) { return true; } return false}) == false ) {
            const obj = {};
            obj["id"] = dataitem.id;
            obj["date"] = dataitem.vd;            
            obj["amount"] = dataitem.v; 
            lineGraph.push(obj);           
          } else {
            for(let i = 0; i < lineGraph.length; i++) {
              if(lineGraph[i]["date"] === dataitem.vd) {
                lineGraph[i]["amount"] += dataitem.v;
                break;
              }
            }         
          }
        });
      } //if - checked
    });

    return lineGraph;

  }

  const MyComponent = ({ xAxisProps: { dataKey: 'date', tick: { fontSize: 12 }, angle:90, tickMargin:30, height:100, tickFormatter:formatXAxis} });

  return (
    <div>
      <center>
        Total graph
      </center>      
      <ResponsiveContainer width="95%" height={300} >
        <AreaChart data={createOverallArray()} >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Area name="Total" type="monotone" dataKey="amount" stroke="#0088FE" fillOpacity={1} fill="url(#colorUv)"/>
        <CartesianGrid stroke="#ccc"/>
        <Tooltip formatter={formatLabel} labelFormatter={formatXAxis}/>
        <YAxis />
        <XAxis {...MyComponent.xAxisProps}/>
        </AreaChart>   
      </ResponsiveContainer>        
    </div>
  )
}

export const WealthIndividual = ({posts, isChecked}) => { 

  const formatXAxis = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm");
  }

  const formatLabel = (value) => {
    // If using moment.js
    return "£" + value.toFixed(2).toString();
  }

  const formatPercentage = (value) => {
    // If using moment.js
    return value.toFixed(2).toString() + "%";
  }

  const renderLines = (data) => {
    var i = 0;
        
    const lines = data.map((item) => {  
      if(isChecked(item.id)) {
        return (
          <Line key={item.id} name={item.n} type="monotone" dataKey={item.n} stroke={item.dc} />
        )
      }
    });
    return lines;    
  }

  const createArray = () => {
    const lineGraph = []
    
    posts.map((post) => {    
      post.wv.map((dataitem) => {
        if(lineGraph.some(i => { if(i.date === dataitem.vd) { return true; } return false}) == false ) {
          const obj = {};
          obj["id"] = dataitem.id;
          obj["date"] = dataitem.vd;            
          obj[post.n] = dataitem.v; 
          obj["amount"] = dataitem.v; 
          lineGraph.push(obj);           
        } else {
          for(let i = 0; i < lineGraph.length; i++) {
            if(lineGraph[i]["date"] === dataitem.vd) {
              lineGraph[i][post.n] = dataitem.v;
              lineGraph[i]["amount"] += dataitem.v;
              break;
            }
          }         
        }
      })
    });

    return lineGraph;

  }

  const MyComponent = ({ xAxisProps: { dataKey: 'date', tick: { fontSize: 12 }, angle:90, tickMargin:30, height:100, tickFormatter:formatXAxis} });

  return (
    <ResponsiveContainer width="95%" height={300} >
      <LineChart width={600} height={400} data={createArray(posts)} >
        {renderLines(posts)}
        <CartesianGrid stroke="#ccc"/>
        <Legend verticalAlign="top" height={50} /> 
        <Tooltip formatter={formatLabel} labelFormatter={formatXAxis}/>
        <YAxis />
        <XAxis {...MyComponent.xAxisProps}/>
      </LineChart>
    </ResponsiveContainer>  
  )
}

export const WealthIndividualTable = ({posts, isChecked}) => { 

  const formatXAxis = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm");
  }

  const formatLabel = (value) => {
    if(value != null) {
      return "£" + value.toFixed(2).toString();
    }
  }

  const formatPercentage = (value) => {
    // If using moment.js
    return value.toFixed(2).toString() + "%";
  }

  const createArray = () => {
    const lineGraph = []
    
    posts.map((post) => {    
      post.wv.map((dataitem) => {
        if(lineGraph.some(i => { if(i.date === dataitem.vd) { return true; } return false}) == false ) {
          const obj = {};
          obj["id"] = dataitem.id;
          obj["date"] = dataitem.vd;            
          obj[post.n] = dataitem.v; 
          obj["amount"] = dataitem.v; 
          lineGraph.push(obj);           
        } else {
          for(let i = 0; i < lineGraph.length; i++) {
            if(lineGraph[i]["date"] === dataitem.vd) {
              lineGraph[i][post.n] = dataitem.v;
              lineGraph[i]["amount"] += dataitem.v;
              break;
            }
          }         
        }
      })
    });

    return lineGraph;

  }

  const createTableHead = (lineGraph) => {
    return lineGraph.map((post) => {
      return (
        <td>
          {formatXAxis(post.date)}
        </td>
      )
    });
  }

  const createColumns = (lineGraph, item) => {
    return lineGraph.map((post) => {
      return (
        <td>
          {formatLabel(post[item])}
        </td>
      )  
    });
  }

  const createTableData = (lineGraph) => {
    return posts.map((post) => {      
      if(isChecked(post.id)) {
        return (
          <tr>
            <td>
              {post.n}
            </td>
            {createColumns(lineGraph, post.n)}
          </tr>
        )
      }
    });
  }

  const createTableFooter = (lineGraph) => {
    return lineGraph.map((post) => {      
      return (
        <td>
          {formatLabel(post.amount)}
        </td>
      )
    });
  }

  const valueColumns = useMemo(
    () => [
      {
        Header: "Item",
        columns: [
          {
            Header: "Dates",
            accessor: "date",
            Cell: ({cell : { value }}) => <>{dateFormat(value, "yyyy/mm/dd")}</> 
          },

        ],
      },
      {
        Header: "Values",
        columns: [
          {
            Header: "Item",
            accessor: "n"
           
          },
          {
            Header: "Date",
            accessor: "v"
            
          }        
        ]
      }
    ]
  ) 

  return (
    <Table columns={valueColumns} data={createArray()} />
  )
}

export const WealthTypeUpdate = ({showData, handleCancel, currentList}) => {
  const [saving, setSaving] = useState(false);
  const [wealthType, setWealthType] = useState({
    id: 0,
    n: "",
    dc: "#ff0000", 
  });
  const [errorMessage, setErrorMessage] = useState("");

  const recordWealthType = async () => {  
    setSaving(true);
    setErrorMessage("");

    var url = new URL("https://thepinkgiraffe.azurewebsites.net/Wealth/InsertType");

    const wealthTypePut = await fetch(url, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify(wealthType),
    })
    .then((response) => {
      if(response.status == 200) {
        handleCancel(true);
        setSaving(false);
      } else {
        setErrorMessage("Wealth type could not be inserted.");
        setSaving(false);
      }
    })    
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      setErrorMessage(err);
      setSaving(false);
    });                
    
    setSaving(false);
  }

  const saveWealthType = () => {
    if(!currentList.some(i => i.n === wealthType)) {
      recordWealthType();
    } else {
      setErrorMessage("Cannot insert wealth type that already exists.");
    }
  }

  useEffect(()=> {
    setWealthType({...wealthType,
      id: showData.dbId,
      n: showData.name,
      dc: showData.displayColour.trim(),
    });
  }, [showData]);

  return (
    <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-wealthtype" >
      <Modal.Header closeButton>
        {showData.dbId > 0 && (
          <Modal.Title id="modal-wealthtype">Update Wealth Type</Modal.Title>
        )}
        {showData.dbId == 0 && (
          <Modal.Title id="modal-wealthtype">Add Wealth Type</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="wealth.type.name">
            <Form.Label>Wealth Item:</Form.Label>
            <Form.Control 
              type="text" 
              value={wealthType.n} 
              onChange={(e) => 
                setWealthType({...wealthType, 
                  n: e.target.value})}
            /> 
          </Form.Group>
          <Form.Group className="mb-3" controlId="wealth.colour">
            <Form.Label>Colour:</Form.Label>
            <Form.Control 
              type="color" 
              value={wealthType.dc} 
              onChange={(e) => 
                setWealthType({...wealthType, 
                  dc: e.target.value})} 
            />            
          </Form.Group>
        </Form>
      </Modal.Body>
      {saving == false && (
        <Modal.Footer>
          <Button variant="primary" onClick={saveWealthType}>Record</Button>
          <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      )}
      {errorMessage != "" && (
        <Alert key='warning' variant='warning'>
          {errorMessage}
        </Alert>
      )}
      {saving == true && (
        <Modal.Footer>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
        </Modal.Footer>
      )}
    </Modal>  
  )
}

export const WealthTypeCombo = ({setTypeID, wealthTypes}) => {
  const [boxTitle, setBoxTitle] = useState("Wealth Type");

  const setWealthTypeID = (id) => {
    setTypeID(parseInt(id));
  }

  const renderDetail = () => {
    const lines = wealthTypes.map((item) => {  
      return (
        <option key={item.id} value={item.id}>{item.n}</option>
      )
    });

    return lines;
  }

  //only run on first render
  useEffect(()=> {
    if(wealthTypes != null && wealthTypes.length > 0) {
      setWealthTypeID(wealthTypes[0].id)
    }
  }, []);

  return(
    <div className="funkydropdown">
      {wealthTypes && wealthTypes.length > 0 && ( 
        <>
        <table>
          <tbody>
            <tr>
              <td>
                <Form.Select aria-label="Select Wealth Type" id="dropdown-basic" title={boxTitle} onChange={(e) => setWealthTypeID(e.target.value)} >
                  {renderDetail()} 
                </Form.Select>    
              </td>
            </tr>
          </tbody>
        </table>     
        </>
      )}
      {wealthTypes == null || wealthTypes.length == 0 && (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder> )} 
    </div>
  )  
}

export const WealthUpdate = ({showData, handleCancel}) => { 
  const [wealthData, setWealthData] = useState({
    Id: 0,
    TypeId: 0,
    Name: "",
    ValueDate: dateFormat(now(), "yyyy-mm-dd"),
    Value: 0.0
  });
  const [wealthTypes, setWealthTypes] = useState([]);
  const [saving, setSaving] = useState(false);

  const readToken = () => {
    var tempToken = sessionStorage.getItem("playerInfo");
     
    return tempToken;
  }

  const setWealthTypeID = (id) => {
    setWealthData({...wealthData,
      TypeId: id
    });
  }

  const readWealthTypes = async (e) => {  
   
    await fetch('https://thepinkgiraffe.azurewebsites.net/Wealth/GetWealthType', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      }
    })  
    .then((response) => response.json() )    
    .then((data) => {
      setWealthTypes(data);    
    })
    .catch((err) => {
      console.log(err);
    }); 
    
  }

  const recordWealthItem = async (e) => {  
    setSaving(true);

    const contributeResponse = await fetch('https://thepinkgiraffe.azurewebsites.net/Wealth/Insert', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify({
        id: wealthData.TypeId,
        vd: wealthData.ValueDate,
        v: wealthData.Value
      }),
    })
    .then((response) => {
      setSaving(false);
      handleCancel(true);
      return true;
    })    
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
      setSaving(false);
    });                
    
    setSaving(false);
  }

  useEffect(()=> {
    readWealthTypes( readToken());
  }, []);

  return (
    <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-wealthitem" >
      <Modal.Header closeButton>
        {showData.dbId == 0 && (
          <Modal.Title id="modal-wealthitem">Add Wealth Item</Modal.Title>
        )}
        {showData.dbId > 0 && (
          <Modal.Title id="modal-wealthitem">Edit Wealth Item - {showData.dbId}</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="wealth.name">
            <Form.Label>Wealth Item:</Form.Label>
            <WealthTypeCombo setTypeID={(e) =>setWealthTypeID(e)} wealthTypes={wealthTypes} />            
          </Form.Group>
          <Form.Group className="mb-3" controlId="wealth.date">
            <Form.Label>Date of Valuation:</Form.Label>
            <Form.Control type="date" value={wealthData.ValueDate} 
                onChange={(e) => setWealthData({...wealthData, 
                                    ValueDate: dateFormat(e.target.valueAsDate, "yyyy-mm-dd")})}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="wealth.value">
            <Form.Label>Value:</Form.Label>
            <br></br>
            <CurrencyInput                  
              placeholder="Value"
              allowDecimals={1}
              prefix="£"
              value={wealthData.Value}
              onValueChange={(value, name, values) => setWealthData({
                ...wealthData,
                Value: values.float})}
            />
          </Form.Group>
         
          <Form.Group className="mb-3" controlId="wealth.save">
            {/* <div>
              {(this.state.successAdd) && <label>Entry successfully added!</label> }
            </div> */}
          </Form.Group>
        </Form>
      </Modal.Body>
      {saving == false && (
        <Modal.Footer>
            <Button variant="primary" onClick={recordWealthItem}>Record</Button>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      )}
      {saving == true && (
        <Modal.Footer>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Savings...</span>
          </Spinner>
        </Modal.Footer>
      )}
    </Modal>  
  )
}


export const WealthInformation = () => { 
  const [messageShow, setMessageShow] = useState(false);
  const [localToken, setLocalToken] = useState("");  
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias',
    loginToken: "8C986FA5-A72E-4EF9-A371-18F40F64D112"
  });
  const [showTypes, setShowTypes] = useState(false);
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const [toggled, setToggled] = useState([]);
  const[showWealthType, setShowWealthType] = useState({
    dbId: 0,
    showModal: false,
    name: "",    
    displayColour: "#000000"
  });

  const[showWealthItem, setShowWealthItem] = useState({
    dbId: 0,
    showModal: false,
    name: "",
    valuationDate: Date('2024-08-15'),
    value: 0.0
  });


  const readToken = () => {
    var tempToken = sessionStorage.getItem("playerInfo");
     
    if(tempToken == null) {
      setToken("blank");
    } else {
      setLocalToken(tempToken);
    }
    return tempToken;
  }

  const readUser = () => {
    var tempUser = sessionStorage.getItem("loggedUser");
     
    return tempUser;
  }

  const getWealth = async (e) => {

    const response = await fetch('https://thepinkgiraffe.azurewebsites.net/Wealth/GetWealthType', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => response.json() )    
      .then((data) => {
        console.log(data);
        setPosts(data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });    
  }

  //only run on first render
  useEffect(()=> {
    getWealth( readToken());
    
  }, []);

  const formatPercentage = (value) => {
    // If using moment.js
    return value.toFixed(2).toString() + "%";
  }

  const editWealthType = (post) => {
    if(post == null) {
      setShowWealthType({...showWealthType,
        showModal: true,
        dbId: 0,
        name: "",
        displayColour: "#FF0000"
      });
    } else {
      setShowWealthType({...showWealthType,
        showModal: true,
        dbId: post.id,
        name: post.n,
        displayColour: post.dc,        
      });  
    }
  }

  const handleCancelType = (reset = false) => {
    setShowWealthType({...showWealthType,
      showModal: false
    });

    if(reset === true) {
      getWealth( readToken());
    }   
  }

  const addWealthItem = () => {

    setShowWealthItem({...showWealthItem,
      dbId: 0,
      showModal: true,
      name: "",
      //valuationDate: currentCont.contribution,
      value: 0.0
    });
    // } else {
    //   setShowWealthItem({...showWealthItem,
    //     showModal: true
    //   });
    // }
  }

  const handleCancel = (reset = false) => {
    setShowWealthItem({...showWealthItem,
      showModal: false
    });

    if(reset === true) {
      getWealth( readToken());
    }
  }

  const isChecked = (id) => {
    return !toggled.some(i => i === id);
  }

  const toggleLines = (id) => {
    //e.preventDefault();    
    //make a copy
    var newArray = toggled.slice();

    if(!newArray.some(i => i === id)) {
      //add the extra one
      newArray.push(id);
    } else {
      newArray = [];
      //add all except the toggled one
      toggled.map((i) => {
        if(i != id) {
          newArray.push(i);
        }
      });
    }

    setToggled(newArray);
  }

  const displayGrowthItems = () => {

    return posts.map((post, i) => {
      return (
        <tr key={post.id}>
          <td>
            {post.n}
          </td>
          <td>
            <section style={{ background: post.dc, width: "80%", color: post.dc }}>{'Colour'}</section>
            
          </td>
          <td>
            <img src="../image/edit2.png" alt="edit" onClick={() => editWealthType(post)} width={32} height={32}/>
            <img src="../image/delete.png" alt="delete" width={32} height={32}/>
          </td>
        </tr>
      )
    })
    
  }

  const formatLabel = (value) => {
    // If using moment.js
    return "£" + value.toFixed(2).toString();
  }
  
  const formatXAxis = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm");
  }

  const growthColumns = useMemo(
    () => [
      {
        Header: "Contributions",
        columns: [
          {
            Header: "Item",
            accessor: "itemName",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Frequency",
            accessor: "recurringDesc",
            Cell: ({cell : { value }}) => <>{value}</>             
          },
          {
            Header: "Amount",
            accessor: "amount",
            Cell: ({cell : { value }}) => <>{value}</> 
          }
        ]
      }
    ]
  )

  const growthTypes = useMemo(
    () => [
      {
        Header: "Types",
        columns: [
          {
            Header: "Item",
            accessor: "n",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Colour",
            accessor: "dc",
            Cell: ({cell : { value }}) => <>{value}</>             
          },
          {
            Header: "Actions",
            Cell: ({row}) => <>
              <img src="../image/edit2.png" alt="edit" width={32} height={32}/>
              <img src="../image/delete.png" alt="delete" width={32} height={32}/>
              </>
          }
        ]
      }
    ]
  )

  return (
    <>
      <div>
        <AlertDismiss heading="Information!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>
      <div>
        <Navbar collapseOnSelect fixed="top" expand="sm" bg="dark" data-bs-theme="dark">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-wealth" />
            <Navbar.Brand href="/login">Login</Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-wealth">
              <Nav className="me-auto">
                <Nav.Link href="/shareinformation">Shares</Nav.Link>
                <Nav.Link href="/pensioninformation">Pension</Nav.Link>
                <Nav.Link href="/growthinformation">Growth</Nav.Link>   
                <Nav.Link href="/logout">Log out</Nav.Link>
                <NavDropdown title="Edit" id="wealth-edit-dropdown">
                  <NavDropdown.Item><div onClick={() => addWealthItem()}>Add Wealth Value</div></NavDropdown.Item>
                  {(!showTypes) && (<NavDropdown.Item><div onClick={(e) => setShowTypes(!showTypes)}>Show Types</div></NavDropdown.Item> )}
                  {(showTypes) && (<NavDropdown.Item><div onClick={(e) => setShowTypes(!showTypes)}>Hide Types</div></NavDropdown.Item> )}
                </NavDropdown>
              </Nav>        
              <Navbar.Text>
                Signed in as: {readUser()}
              </Navbar.Text>  
            </Navbar.Collapse>
           
          </Container>        
        </Navbar>
      </div>

      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Wealth</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Welcome {userData.alias}</Badge>
      </h2>
      <h3 style={{display: "grid", justifyContent: "center"}}>
        
      </h3>
      <h4>

      </h4>

      {showWealthItem != null && (
          <WealthUpdate showData={showWealthItem} handleCancel={handleCancel}/>
      )}

      {showWealthType && (
          <WealthTypeUpdate showData={showWealthType} handleCancel={handleCancelType} currentList={posts} />
        )}  

      <Container fluid="md">
        <Row className="justify-content-md-center">
          <Col sm={12} md={12} lg={12} xl={12} xxl={6}>
            {posts != null && posts.length > 0 && (
              <WealthIndividual posts={posts} isChecked={isChecked} />
            )}
            {(posts == null || posts.length == 0) && (
              <center>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </center>
            )}
                      {/* <table>
            <tbody>          
              <tr>
                <td>
                  Growth since last: {posts.growthLast}
                </td>
              </tr>
              <tr>
                <td>
                  Growth month: {posts.growthMonth}
                </td>
              </tr>
              <tr>
                <td>
                  Growth Year: {posts.growthYear}
                </td>
              </tr>
          </tbody>
        </table>   */}
          </Col>
          <Col sm={12} md={12} lg={12} xl={12} xxl={6}>
            
            {posts != null && posts.length > 0 && (
              <WealthOverall posts={posts} isChecked={isChecked} />
            )}
            {(posts == null || posts.length == 0) && (
              <center>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </center>
            )}           
          </Col>
        </Row>
        <Row>
         
          <Col xxl={12}>

          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col sm={6} md={2}>
            <table>
              <tbody>
                {posts.slice(0, (posts.length / 2)).map((post) => {
                    var checked = isChecked(post.id);

                    return (         
                      <tr key={post.id}>
                        <td style={{ padding: '5px', border: '1px', margin: '0 10px'}}>
                          <label>
                            <input id={post.id} type="checkbox" checked={checked} onChange={() => toggleLines(post.id)}/>
                            {post.n}
                          </label>
                        </td>
                      </tr>
                    )
                })}
              </tbody>
            </table>
          </Col>
          <Col sm={6} md={2}>
            <table>
              <tbody>          
                {posts.slice((posts.length / 2)).map((post) => {
                  var checked = isChecked(post.id);
                  return (         
                    <tr key={post.id}>
                      <td style={{ padding: '5px', border: '1px', margin: '0 10px'}}>
                        <label>
                          <input id={post.id} type="checkbox" checked={checked} onChange={() => toggleLines(post.id)}/>
                          {post.n}
                        </label>
                      </td>
                    </tr>
                  )
                })} 
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={6} xl={6} xxl={6} >
            {showTypes == true && (
              <>
                {posts != null && posts.length > 0 && (
                  <>
                    <Button variant="primary" onClick={() => editWealthType(null)}>Add New Item</Button>
                    <table>
                      <thead>
                        <tr>
                          <td>
                            Item
                          </td>
                          <td>
                            Colour
                          </td>
                          <td>
                            Actions
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {displayGrowthItems()}
                      </tbody>
                    </table>            
                  </>
                )}
              </>
            )}
          </Col>
          <Col sm={12} md={12} lg={6} xl={6} xxl={6} >
          </Col>
        </Row>
      </Container>
    </>
  );
  
}