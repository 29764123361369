import { Component } from 'react';
import { useState, useEffect, useMemo } from 'react';
import { Badge, Container, Row, Col, Dropdown, DropdownButton, Placeholder, Form, Modal, Button, Spinner} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip ,Legend, ResponsiveContainer } from 'recharts';
import { AlertDismiss } from './AlertDismiss.js';
import dateFormat from 'dateformat';
import Table from "./Table.js";
import FinancialEntry from './FinancialEntry.js';
import moment from 'moment'

export const RecurringCombo = ({setRecurringID, recurringItems, selectedItem}) => {

  const setRecurID = (id) => {
    setRecurringID(id);
  }

  const renderDetail = () => {
    const lines = recurringItems.map((item) => {  
      return (        
        <option key={item.id} value={item.id}>{item.name}</option>
      )    
    });

    return lines;
  }

  //only run on first render
  useEffect(()=> {
  }, []);

  return(
    <div className="funkydropdown">
      {recurringItems && recurringItems.length > 0 && ( 
        <Form.Select aria-label="Select Frequency" id="dropdown-basic" onChange={(e) => setRecurID(parseInt(e.target.value))} defaultValue={selectedItem}>
          {renderDetail()} 
        </Form.Select>    
      )}
      {recurringItems == null || recurringItems.length == 0 && (
        <Placeholder animation="glow"><Placeholder xs={6} /></Placeholder> 
      )} 
    </div>
  )  
}

export const GrowthForecast = ({growthForecast}) => { 

  const formatLabel = (value) => {
    if(value == null) {
      return "£0.00";
    } else {
      // If using moment.js
      return "£" + value.toFixed(2).toString();
    }
  }
  
  const formatXAxis = (value) => {
    // If using moment.js
    return dateFormat(value, "yyyy/mm");
  }

  const createOverallArray = (growthForecast) => {
    const lineGraph = []
    
    if(growthForecast != null) {

      growthForecast.low.g.map((post) => {    
        const obj = {};
        obj["low"] = post.w;
        obj["high"] = 0.0;
        obj["expected"] = 0.0;
        obj["date"] = post.fd;

        lineGraph.push(obj);
      });
      
      iCnt = 0;

      growthForecast.exp.g.map((post) => { 
        if(lineGraph.length <= iCnt) {
          const obj = {};
          obj["high"] = 0.0;
          obj["expected"] = post.w;
          obj["date"] = post.fd;
          lineGraph.push(obj)
        } else {
          lineGraph[iCnt++]["expected"] = post.w;
        }
      });

      var iCnt = 0;

      growthForecast.high.g.map((post) => {              
        if(lineGraph.length <= iCnt) {
          const obj = {};
          obj["high"] = post.w;
          obj["date"] = post.fd;

          lineGraph.push(obj)
        } else {
          lineGraph[iCnt++]["high"] = post.w;
        }
      });


    }

    return lineGraph;
  }

  const createGraph = () => {
    var Growth = null;

    if(growthForecast != null) {
      Growth = createOverallArray(growthForecast);
    }
    
    const MyComponent = ({ xAxisProps: { dataKey: 'date', tick: { fontSize: 10 }, angle:90, tickMargin:30, height:100, tickFormatter:formatXAxis},
                           yAxisProps: { tick: {fontSize: 9}} });

    return (
      <div>
        {Growth && Growth.length > 0 && (
          <ResponsiveContainer width="95%" height={350} >
            Total graph                
            <LineChart data={Growth} >
              <Line name="High" type="monotone" dataKey="high" stroke="#0088FE" />
              <Line name="Expected" type="monotone" dataKey="expected" stroke="#008800" />
              <Line name="Low" type="monotone" dataKey="low" stroke="#FE0000" />
              <CartesianGrid stroke="#ccc"/>
              <Legend verticalAlign="top" height={30}/> 
              <Tooltip formatter={formatLabel} labelFormatter={formatXAxis}/>
              <YAxis {...MyComponent.yAxisProps} />
              <XAxis {...MyComponent.xAxisProps} />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    )
  }

  useEffect(()=> {
   // getForecast(readToken()); 
  }, []);

  return (
    <Row>
      <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
        {createGraph()}
      </Col>
      <Col xxl={6} xl={6} lg={6} md={12} sm={12} className="d-flex align-items-center justify-content-center">
        {growthForecast && (        
          <table cellPadding={5} >
            <thead>
              <tr>
                <th colSpan={2} align='center'>
                  Low
                </th>
                <th colSpan={2} align='center'>
                  Expected
                </th>
                <th colSpan={2} align='center'>
                  High
                </th>
              </tr>
              <tr>
                <th align='center'>
                  Total Interest
                </th>
                <th align='center'>
                  Total
                </th>
                <th align='center'>
                  Total Interest
                </th>
                <th align='center'>
                  Total
                </th>
                <th align='center'>
                  Total Interest
                </th>
                <th align='center'>
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {formatLabel(growthForecast?.low?.int)}
                </td>
                <td>
                  {formatLabel(growthForecast?.low?.tot)}
                </td>
                <td>
                  {formatLabel(growthForecast?.exp?.int)}
                </td>
                <td>
                  {formatLabel(growthForecast?.exp?.tot)}
                </td>
                <td>
                  {formatLabel(growthForecast?.high?.int)}
                </td>
                <td>
                  {formatLabel(growthForecast?.high?.tot)}
                </td>
              </tr>
            </tbody>
          </table>
        )}    
      </Col>
    </Row>
  )
}


export const GrowthItem = ({recurringItems, showData, handleCancel}) => {
  const [growthRecord, setGrowthRecord] = useState({
    ID: 0,
    ItemName: "",
    RecurringID: 1,
    RecurringDesc: "",
    Amount: 0.0,
    IncreasePercentage: 0.0
  });
  const [saving, setSaving] = useState(false);
  
  const recordGrowthItem = async (e) => { 
    setSaving(true);

    console.log(growthRecord);

    var url = 'https://thepinkgiraffe.azurewebsites.net/Growth';
    
    const contributeResponse = await fetch(url, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem("playerInfo"),
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify(growthRecord),
    })
    .then((response) => {
      console.log(response);   
      handleCancel(true);
      return true;
    })    
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log(err);
    });        
    
    setSaving(false);
  }

  const setRecurringID = (value) => {
    setGrowthRecord({
      ...growthRecord,
      RecurringID: value});
  }

  //only run on first render
  useEffect(()=> {
    setGrowthRecord({
      ...growthRecord,
      ID: showData.dbId,
      ItemName: showData.itemName,
      RecurringID: showData.recurringId,
      RecurringDesc: showData.recurringDesc,
      Amount: showData.amount,
      IncreasePercentage: showData.increasePercentage});    

  }, [showData]);

  return(
    <Modal backdrop="static" show={showData.showModal} onHide={handleCancel} centered aria-labelledby="modal-growthitem" >
      <Modal.Header closeButton>
        {showData.dbId > 0 && (
          <Modal.Title id="modal-growthitem">Update Growth Item</Modal.Title>
        )}
        {showData.dbId == 0 && (
          <Modal.Title id="modal-growthitem">Add Growth Item</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="growth.item">
            <Form.Label>Growth Item:</Form.Label>
            <Form.Control                       
              type="text" 
              value={growthRecord.ItemName}
              onChange={(e) => setGrowthRecord({
                ...growthRecord,
                ItemName: e.target.value})}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="growth.amount">
            <Form.Label>Amount saved:</Form.Label>
            <Form.Control
              type="number"
              value={growthRecord.Amount}
              onChange={(e) => setGrowthRecord({
                ...growthRecord,
                Amount: parseFloat(e.target.value)})}
            />
            {/* <FinancialEntry label="Amount saved:" value={growthRecord.Amount} onChange={(e) => {setGrowthRecord({
                          ...growthRecord,
                          Amount: e})}} increment={50} />   */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="growth.frequency">
            <Form.Label>Frequency:</Form.Label>
            <RecurringCombo setRecurringID={(e) =>setRecurringID(e)} recurringItems={recurringItems} selectedItem={showData.recurringDesc}/>
          </Form.Group>
          <Form.Group className="mb-3" controlId="growth.increase">
            <Form.Control 
              type="number"
              value={growthRecord.IncreasePercentage}
              onChange={(e) => setGrowthRecord({
                ...growthRecord,
                IncreasePercentage: parseFloat(e.target.value)})}      
            />       
            {/* <FinancialEntry label="Increase Percentage" value={growthRecord.IncreasePercentage} prefix="0" onChange={(e) => {setGrowthRecord({
                        ...growthRecord,
                        IncreasePercentage: e})}} increment={0.5} />   */}
          </Form.Group>
        </Form>
      </Modal.Body>
      {saving == false && (
        <Modal.Footer>
              <Button variant="primary" onClick={recordGrowthItem}>Record</Button>
              <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
        </Modal.Footer>
      )}
      {saving == true && (
        <Modal.Footer>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
        </Modal.Footer>
      )}
    </Modal>             
  )
}

export const OverallInformation = ({wealthInformation, setWealthInformation}) => { 
  const [startDate, setStartDate] = useState(new Date());
  const [saving, setSaving] = useState(false);

  const recordWealthInformation = async (e) => {
    setSaving(true);

    const response = await fetch('https://thepinkgiraffe.azurewebsites.net/Wealth/Information', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
      body: JSON.stringify(wealthInformation),
    })
      .then((response) => response.json() )    
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      }); 
      
    setSaving(false);      
  }

  const readToken = () => {
    var tempToken = sessionStorage.getItem("playerInfo");
         
    return tempToken;
  }

  const getYear = (date) => {
    let dateM = moment(date, "YYYY-MM-DD");
    return dateM.format("YYYY");
  }

  const getMonth = (date) => {
    let dateM = moment(date, "YYYY-MM-DD");
    return dateM.format("MM");
  }

  const renderMonth = () => {
    var month = []
    for(let i = 1; i < 13; i++) {
      const obj = {};
      obj["month"] = i.toString();
      month.push(obj);
    }

    const lines = month.map((post, i) => {
      return (
        <option key={i} eventKey={post.month}>{post.month}</option>
      )
    });

    return lines;
  }

  const setMonth = (month) => {
    setWealthInformation({
      ...wealthInformation,
      retirementDate: new Date(getYear(wealthInformation.retirementDate), month, 1)
    });
  }

  const setYear = (year) => {
    setWealthInformation({
      ...wealthInformation,
      retirementDate: new Date(year, getMonth(wealthInformation.retirementDate), 1)
    });
  }

  const renderDetail = () => {
    var years = []
    for(let i = 0; i < 40; i++) {
      const obj = {};
      obj["year"] = new Date().getFullYear() + i;
      years.push(obj);
    }

    const lines = years.map((post, i) => {
      return (
        <option key={i} value={post.year}>{post.year}</option>
      )
    });

    return lines;
  }

  useEffect(()=> {
   // getWealthInformation(readToken());  
  }, []);

  const saveWealthInformation = (e) => {
    e.preventDefault();
    
    recordWealthInformation(readToken());
  }

  return(
    <Form>
      <Form.Group>
        <Form.Check 
          type="switch" 
          id="include-inflation-switch"
          label="Include Inflation"
          checked={wealthInformation.includeInflation}
          onChange={(e) => setWealthInformation({
            ...wealthInformation,
            includeInflation: e.target.checked})}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Inflation:</Form.Label>
        <Form.Control 
          type="number"
          value={wealthInformation.inflation} 
          disabled={!wealthInformation.includeInflation} 
          onChange={(e) => {setWealthInformation({
                        ...wealthInformation,
                        inflation: parseFloat(e.target.value)})}} />
      {/* <FinancialEntry label="Inflation:" 
                      value={wealthInformation.inflation} 
                      prefix=""
                      disabled={!wealthInformation.includeInflation} 
                      onChange={(e) => {setWealthInformation({
                                    ...wealthInformation,
                                    inflation: e})}} increment={0.5} />   */}

        </Form.Group>
        <Form.Group>
          <Form.Label>Growth Assumption:</Form.Label>
          <Form.Control 
            type="number"
            value={wealthInformation.growthAssumptions} 
            onChange={(e) => {setWealthInformation({
                          ...wealthInformation,
                          growthAssumptions: parseFloat(e.target.value)})}} 
          />   
            {/* <FinancialEntry label="Growth Assumption:" 
                      value={wealthInformation.growthAssumptions}    
                      prefix=""                           
                      onChange={(e) => {setWealthInformation({
                                  ...wealthInformation,
                                  growthAssumptions: e})}} increment={0.5} />                                                    */}
        </Form.Group>
        <Form.Group>
          <Form.Label>Retirement Date:</Form.Label>
          <Row>
            <Col xxl={6} xl={6} l={6} sm={6} md={6}>
              Month:
              <Form.Select id="dropdown-basic" title={dateFormat(wealthInformation.retirementDate, "mm")} onChange={(e) => setMonth(e.target.value)}>
                {renderMonth()} 
              </Form.Select>    
            </Col>
            <Col xxl={6} xl={6} l={6} sm={6} md={6}>
              Year:
              <Form.Select id="dropdown-basic" title={dateFormat(wealthInformation.retirementDate, "yyyy")} onChange={(e) => setYear(e.target.value)}>
                {renderDetail()} 
              </Form.Select>                     
            </Col>          
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label>Draw Down:</Form.Label>
          <Form.Control 
            type="number"
            value={wealthInformation.drawdown} 
            onChange={(e) => {setWealthInformation({
                          ...wealthInformation,
                          drawdown: parseFloat(e.target.value)})}} 
          />  
          {/* <FinancialEntry label="Draw Down" value={wealthInformation.drawdown} onChange={(e) => {setWealthInformation({
            ...wealthInformation,
            drawdown: e})}} increment={500} />                        */}
        </Form.Group>
        <Form.Group>
          <Form.Label></Form.Label>
          {saving == false && (
            <center>
              <Button variant="primary" onClick={(e) => saveWealthInformation(e)}>Save</Button>
            </center>
          )}
          {saving == true && (
            <center>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Saving...</span>
              </Spinner>
            </center>
          )}
        </Form.Group>
    </Form>
  ) 
}



export const GrowthInformation = () => { 
  const [messageShow, setMessageShow] = useState(false);
  const [localToken, setLocalToken] = useState("");  
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias',
    loginToken: "8C986FA5-A72E-4EF9-A371-18F40F64D112"
  });
  const [wealthInformation, setWealthInformation] = useState(null);
  const [recurringItems, setRecurringItems] = useState([]);
  const [growthItems, setGrowthItems] = useState([]);
  const [growthForecast, setGrowthForecast] = useState(null);

  const[showModal, setShowModal] = useState({
    Id: 0,
    DbId: 0,
    Show: false
  });

  const [showGrowthItem, setShowGrowthItem] = useState({
    dbId: 0,
    showModal: false,
    itemName: "",
    recurringID: 0,
    recurringDesc: "",
    amount: 0,
    increasePercentage: 0
  });


  const readToken = () => {
    var tempToken = sessionStorage.getItem("playerInfo");
     
    if(tempToken == null) {
      setToken("blank");
    } else {
      setLocalToken(tempToken);
    }
    return tempToken;
  }

  const readRecurring = async (e) => {  
   
    await fetch('https://thepinkgiraffe.azurewebsites.net/Budget/Recurring', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Origin': 'https://localhost:44386'        
      }
    })  
    .then((response) => response.json() )    
    .then((data) => {
      setRecurringItems(data);
    })
    .catch((err) => {
      console.log(err);
    }); 
    
  }

  const getGrowthItems = async (e) => {

    const response = await fetch('https://thepinkgiraffe.azurewebsites.net/Growth/Items', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => response.json())    
      .then((data) => {
        setGrowthItems(data);
        getWealthInformation(e);  
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });    
  }  

  const getWealthInformation = async (e) => {

    const response = await fetch('https://thepinkgiraffe.azurewebsites.net/Wealth/GetInformation', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => response.json() )    
      .then((data) => {
        console.log(data);
        setWealthInformation(data);
        getForecast(e);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });    
  }  

  const getForecast = async (e) => {

    const response = await fetch('https://thepinkgiraffe.azurewebsites.net/Growth/Forecast', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + e,
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => response.json() )    
      .then((data) => {        
        setGrowthForecast(data);                
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });    
  }

  const deleteGrowthItem = async (id) => {
    var output = false;
    var url = new URL("https://thepinkgiraffe.azurewebsites.net/Growth");
    url.searchParams.append("growthItemId", id);

    const valueResponse = await fetch(url, {
      method: 'DELETE',      
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + readToken(),
        'Origin': 'https://localhost:44386'        
      },
    })
      .then((response) => {
        output = true;
        return true;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.messsage);
      });   
      
    return output;
  }

  const deleteRecord = async () => {
    var deleted = await deleteGrowthItem(showModal.DbId);

    if(deleted == true) {
      //hide the confirmation box
      hideModal();
      getGrowthItems(readToken()); 
    } else {
      //hide the confirmation box
      hideModal();
      //show the error to the audience
      setAlertMessage("Error while deleting");
      setMessageShow(true);
    }
  }

  const confirmDelete = (id) => {

    setShowModal({
      Id: id,
      DbId: growthItems[id].id,
      Show: true
    });
  }

  const hideModal = (reset = false) => {
    setShowModal({...showModal, Show: false});
  } 

  const showGrowthItemEdit = (id) => {
    if(id == -1) {
      setShowGrowthItem({...showGrowthItem,
        dbId: 0,
        itemName: "",
        recurringID: 1,
        recurringDesc: "",
        amount: 50,
        increasePercentage: 2,
        showModal: true        
      });

    } else if(growthItems != null &&  growthItems.length > id) {
      var currentVal = growthItems[id];

      setShowGrowthItem({...showGrowthItem,
        dbId: currentVal.id,
        itemName: currentVal.itemName,
        recurringID: currentVal.recurringId,
        recurringDesc: currentVal.recurringDesc,
        amount: currentVal.amount,
        increasePercentage: currentVal.increasePercentage,
        showModal: true
      });
    }
  }

  const handleGrowthItemCancel = (reset = false) => {
    setShowGrowthItem({...showGrowthItem,
      showModal: false
    });

    if(reset === true) {
      getGrowthItems(readToken()); 
    }
  }
  //only run on first render
  useEffect(()=> {
    readRecurring();
    getGrowthItems(readToken()); 
  }, []);

  const getBody = () => {

    if(growthItems != null && growthItems.length > 0  && showModal.Id >= 0 && growthItems.length > showModal.Id) {
      return (
        <Modal.Body>
          <p>Are you sure you want to delete the record          
          {' '}
          {growthItems[showModal.Id].itemName}          
          {'?'}
          </p>
        </Modal.Body>
      )
    }
  }

  const growthColumns = useMemo(
    () => [
      {
        Header: "Contributions",
        columns: [
          {
            Header: "Item",
            accessor: "itemName",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Frequency",
            accessor: "recurringDesc",
            Cell: ({cell : { value }}) => <>{value}</>             
          },
          {
            Header: "Amount",
            accessor: "amount",
            Cell: ({cell : { value }}) => <>{value}</> 
          },
          {
            Header: "Actions",
            Cell: ({row}) => <>
              <img src="../image/edit2.png" alt="edit" onClick={() => showGrowthItemEdit(row.index)} width={32} height={32}/>
              <img src="../image/delete.png" alt="delete" onClick={() => confirmDelete(row.index)} width={32} height={32}/>
              </>
          }
        ]
      }
    ]
  )

  return (
    <>
      <div>
        <AlertDismiss heading="Information!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>
      <h1 style={{display: "grid", justifyContent: "center"}}>
        
        <Badge bg="primary" >Wealth</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Welcome {userData.alias}</Badge>
      </h2>
      <h3 style={{display: "grid", justifyContent: "center"}}>
        
      </h3>
      <h4>

      </h4>
      <Modal show={showModal.Show} onHide={hideModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>

        {getBody()}

        <Modal.Footer>
          <Button variant="primary" onClick={deleteRecord}>Yes</Button>
          <Button variant="secondary" onClick={hideModal}>No</Button>
        </Modal.Footer>
      </Modal>
      {/* growth update modal box */}

      <GrowthItem recurringItems={recurringItems} showData={showGrowthItem} handleCancel={handleGrowthItemCancel}/>

      <Container flued="md">
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
            {wealthInformation != null && (
              <OverallInformation wealthInformation={wealthInformation} setWealthInformation={setWealthInformation} />
            )}
            {wealthInformation == null && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Saving...</span>
              </Spinner>
            )}            
          </Col>
          <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
            {growthItems != null && growthItems.length > 0 && (        
              <>
                <Button variant="primary" onClick={() => showGrowthItemEdit(-1)}>Add New Item</Button>
                <Table columns={growthColumns} data={growthItems}/>
              </>
            )}
            {growthItems == null && (        
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Saving...</span>
              </Spinner>
            )}   
          </Col>
        </Row>
        <Row>
          <Col>
          </Col>
        </Row>
        {growthForecast != null && (
          <GrowthForecast growthForecast={growthForecast}/>
        )}
        {growthForecast == null && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Saving...</span>
          </Spinner>
        )}            
      </Container>
    </>
  );
  
}