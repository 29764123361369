import { Component } from 'react';
import CurrencyInput from 'react-currency-input-field';
import './App.scss';

class FinancialEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
        value: 10000,
        increment: 500
        } 
    }
    
    increaseNumber = () => {
        const {value, onChange, increment} = this.props;    

        onChange(value + increment);
    }

    decreaseNumber = () => {
        const {value, onChange, increment} = this.props;    

        onChange(value - increment);
    }

    render() {
        const {label, value, onChange, increment, prefix, disabled} = this.props;    

        if(prefix == null) {
        prefix = "£";
        }
        this.state.value = value;
        this.state.increment = increment;

        return (
        <table className='financial-table'>
            <tbody>
            <tr>
                <td colSpan={3} align='center'>
                <label>
                    {label}
                </label>
                </td>
            </tr>
            <tr>
                <td>
                    <button className='financial-button' disabled={disabled} style={{backgroundImage:"url('../image/minus.png')"}} alt="decrease value" onClick={this.decreaseNumber}></button>
                </td>
                <td align='center'>
                    <CurrencyInput 
                        className='financial'
                        placeholder={label}
                        allowDecimals={true}
                        disabled={disabled}
                        prefix={prefix}
                        value={value}
                        onValueChange={(value, name, values) => onChange(values.float)}
                />   
                </td>
                <td>
                    <button className='financial-button' disabled={disabled} style={{backgroundImage:"url('../image/plus.png')", backgroundSize:"cover"}} alt="increase value" onClick={this.increaseNumber}></button>
                </td>
            </tr>
            </tbody>

        </table>
        )
    }
}

FinancialEntry.defaultProps = {
    prefix: "£",
    disabled: false
};

export default FinancialEntry;