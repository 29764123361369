import { Component } from 'react';

export class DWButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validPassword: false,
            showPassword: false,
            style:{bakground: 'red', color: 'black'},
            message: 'Welcome visitor'
        }
    }

    changeMessage() {
        this.setState({
            message: 'Changed'
        });
    }



    render() {
        const {style} = this.state;
        const {whenClicked, value } = this.props;

        return (
            <div>
                <p>{this.state.message}</p>
                <button style={style} onClick={() => this.changeMessage()}>{value}</button>
                <p>{this.props.value}</p>
            </div>
        )
    }
}


