import { Component } from 'react';
import { useState, useEffect } from 'react';
import { Badge, Form, Button, Spinner} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { AlertDismiss } from './AlertDismiss.js';
import { UserConfirmationBox} from './UserConfirmationBox.js';

export const EMAIL_REGEX = new RegExp(/^(?:[a-z0-9_]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/, "i");

export const ForgotPassword = () => { 
  const [messageShow, setMessageShow] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [confirmationBox, setConfirmationBox] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [userData, setUserData] = useState({
    username: 'user@user.com',
    password: 'Test123',
    alias: 'Alias',
    loginToken: "8C986FA5-A72E-4EF9-A371-18F40F64D112"
  });
  const [saving, setSaving] = useState(false);

  const ResetPasswordWS = async (data) => {
    console.log(JSON.stringify(data));
    var success = false;
    setSaving(true);

    const response = await fetch('https://localhost:44386/api/User/forgot', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': 'https://localhost:44386'
      },
    })    
      .then((response) => {
        success = true;
        setSaving(false);
        return response;
      })
      .catch((err) => {
        console.log("Error");
        console.log(err);

      });

    if (response) {
      console.log(response);
    }

    setSaving(false);
    return success;
  }

  const handleSubmit = (e) => {  
    e.preventDefault();
    setMessageShow(false);
    setAlertMessage("");
    setButtonDisabled(true);    
    
    if(userData.username.match(EMAIL_REGEX, "i")) {
      //clear the error message
      setAlertMessage('');
      ResetPasswordWS(userData).then((result) => {
        if(result == true) {
          //close the dialog
          //maybe show a confirmation before this?
          setConfirmationBox(true);
        } else {
          //error during insert of new user
          setAlertMessage('An error occurred while trying to reset password. Please try again.'); 
          setMessageShow(true);  
        }
      });
    } else {
      setAlertMessage('Email address not valid'); 
      setMessageShow(true);   
    }

    setButtonDisabled(false);    
  };

  const handleCancel = (e) => {
    e.preventDefault();

  }

  const UserInputBox = () => {

    return(
    <>
      <div>
        <AlertDismiss heading="Forgot password request error!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>

      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Welcome to Lexi Game</Badge>
      </h1>
      <h2 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >Forgot Password</Badge>
      </h2>
      <Form>
        <Form.Group className="mb-3" controlId="forgot.email">
          <Form.Label>Email address:</Form.Label>
          <Form.Control 
            type="email" 
            value={userData.username} 
            onChange={(e) => setUserData({
              ...userData, 
              username: e.target.value})}
            aria-describedBy="emailHelpBlock"              
          />
          <Form.Text id="emailHelpBlock" muted>
            This will be your username you registered with.
          </Form.Text>
        </Form.Group>        
        <Form.Group className="mb-3" controlId="forgot.buttons">
          {saving == false && (
            <>
              <Button variant="primary" onClick={(e) => handleSubmit(e)}>Submit</Button>
              {' '}
              <Button variant="secondary"onClick={(e) => handleCancel(e)}>Cancel</Button>
            </>
          )}
          {saving == true && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Savings...</span>
            </Spinner>
          )}
        </Form.Group>            
      </Form>      
    </>);
  }


  return (
    <>
    {confirmationBox === true ?
      (
        <div>
          <UserConfirmationBox 
            header="Forgot Password"
            message="Forgot password request has been successful!"
            messageDetail="Please check your email account for an email that will allow you to reset your password.
            We will only send a forgot password link to your email address if a corresponding account has been found."
            handleCancel={handleCancel}
          />
        </div>
      ) :
      (
        <div>              
          <UserInputBox />
        </div>
      )
    }
    </>
  )  
}