import { Alert } from 'react-bootstrap';
import { Button, Col } from 'react-bootstrap';

export const AlertDismiss = (props) => {
    return (
        <>
        {props.messageShow === true? (
        
        <Alert show={true} onClose={() => props.setMessageShow(false)} variant="warning" dismissible>
            <Alert.Heading>{props.heading}</Alert.Heading>
            <p>
                {props.message}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={() => props.setMessageShow(false)} variant="outline-success">
                Close this popup
                </Button>
            </div>
        </Alert>
        
        ): (
            <div>
            </div>
        )
        }
        </>
    )
  }