import { useState, useEffect } from 'react';
import { Badge, Container, Navbar, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import { AlertDismiss } from './AlertDismiss.js';

export const LogOut = () => { 
  const [messageShow, setMessageShow] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const clearToken = () => {
    sessionStorage.setItem("playerInfo", null);
    sessionStorage.setItem("loggedUser", "");
  }
  //only run on first render
  useEffect(()=> {
    clearToken();
  }, []);


  return (
    <>
      <div>
        <AlertDismiss heading="Information!" message={alertMessage} messageShow={messageShow} setMessageShow={setMessageShow}/>
      </div>

      <div>
        <Navbar collapseOnSelect fixed="top" expand="sm" bg="dark" data-bs-theme="dark">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-logout" />
            <Navbar.Brand href="/login">Login</Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-logout">
              <Nav className="me-auto">
              </Nav>        
              <Navbar.Text>
                Signed in as: 
              </Navbar.Text>  
            </Navbar.Collapse>
           
          </Container>        
        </Navbar>
      </div>
      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="primary" >Logged Out</Badge>
      </h1>
      <h3 style={{display: "grid", justifyContent: "center"}}>
        You can log in again here: <a href="/login">Login</a>
      </h3>
      <h4>

      </h4>
    </>
  );
  
}