import React from "react";
import { useTable, usePagination} from "react-table";

export default function Table({ columns, data }) {
    // Use the useTable Hook to send the columns and data to build the table
    const {
      getTableProps, // table props from react-table
      getTableBodyProps, // table body props from react-table
      headerGroups, // headerGroups, if your table has groupings
      page, // rows for the table based on the data passed
      nextPage,
      previousPage,
      canPreviousPage,
      canNextPage,
      gotoPage,
      pageOptions,
      state,
      prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
      } = useTable({
          columns,
          data
        },
        usePagination
      );
      
    const {pageIndex} = state;
    /* 
      Render the UI for your table
      - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
    */
    return (
      <>
        <table {...getTableProps()} className="tableContainer"  >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, j) => ( 
                  <th {...column.getHeaderProps()} className={column.className} key={j}>{column.render("Header")}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="tableContainer" >
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, j) => {
                    return <td key={j} {...cell.getCellProps()} className={cell.column.className}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <span>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <button disabled={!canPreviousPage} onClick={() => {gotoPage(0)}} className="button">
            First
          </button>
          <button disabled={!canPreviousPage} onClick={() => {previousPage()}} className="button">
            Previous
          </button>
          <button disabled={!canNextPage} onClick={() => {nextPage()}} className="button">
            Next
          </button>
          <button disabled={!canNextPage} onClick={() => {gotoPage(pageOptions.length-1)}} className="button">
            Last
          </button>
        </div>
      </>
    );
  }