import { Button } from 'react-bootstrap';
import { Badge } from 'react-bootstrap';

export const UserConfirmationBox = (props) => {
  const handleCancel = (e) => {
    props.handleCancel(e);
  }


  return (
    <>
      <h1 style={{display: "grid", justifyContent: "center"}}>
        <Badge bg="secondary" >{props.header}</Badge>
      </h1>
      <div style={{display: "grid", justifyContent: "center"}}>
        <h2>
          {props.message}
        </h2>
      </div>
      <div style={{display: "grid", justifyContent: "center"}}>
        <p>
          {props.messageDetail}
        </p>
      </div>
      <div className="d-flex justify-content-end">
          <Button onClick={(e) => handleCancel(e)} variant="outline-success">
            Return to Login
          </Button>
      </div>          

    </>
  )
}